@use '@shared/util-styling/tokens';
@use '@shared/util-styling/mixins';

.chart-section {
  $skeleton-opacity: 0.5;

  color: var(--mdrt-color-text-on-dark-primary);
  font: var(--mdrt-headline-2);

  &__heading {
    display: flex;
    flex-direction: column;

    @include mixins.breakpoint('medium') {
      flex-direction: row;
      gap: var(--spacing-16);
      justify-content: space-between;
    }
  }

  &__headline--visually-hidden {
    height: 0;
    overflow: hidden;
    width: 0;
  }

  &__headline-skeleton {
    height: 40px;
    opacity: $skeleton-opacity;
    width: 300px;
  }

  &__tabs-container {
    align-self: flex-start;
    background-color: var(--mdrt-color-backgrounds-bg-neutral-hover-inverse);
    border-radius: var(--mdrt-corner-radius-m);
    display: flex;
    gap: var(--spacing-8);
    margin-block: var(--spacing-16);
    padding: var(--spacing-4);
    width: fit-content;

    &--hide {
      display: none;
    }

    @include mixins.breakpoint('medium') {
      margin-block: 0;
    }

    @include mixins.breakpoint('small') {
      &--hide {
        display: flex;
      }
    }
  }

  &__tabs-container-skeleton {
    height: 40px;
    margin-block: var(--spacing-16);
    opacity: $skeleton-opacity;
    width: 186px;

    @include mixins.breakpoint('medium') {
      margin-block: 0;
    }
  }

  &__tab {
    $tab-border-radius: 5px;

    @include mixins.button-reset;

    border-radius: $tab-border-radius;
    color: var(--mdrt-color-text-on-dark-primary);
    cursor: pointer;
    font-size: var(--font-size-s);
    padding: var(--spacing-4) var(--spacing-12);

    &--is-selected {
      background-color: tokens.$mdrt-buttons-primary-bg-fill;
      color: tokens.$mdrt-buttons-primary-text-enabled;
    }

    &:focus {
      outline: none;
    }

    &--focus-visible {
      &:focus {
        outline: auto;
      }
    }
  }

  &__sub-heading {
    color: var(--mdrt-color-text-on-dark-secondary);
    font: var(--mdrt-title-2);

    &--no-tabs {
      margin-block: var(--spacing-16) 52px;
    }

    @include mixins.breakpoint('small') {
      margin-block: 0;
    }

    @include mixins.breakpoint('medium') {
      margin-top: var(--spacing-16);
    }
  }

  &__sub-heading-skeleton-container {
    margin-top: var(--spacing-16);
  }

  &__sub-heading-skeleton {
    height: 20px;
    opacity: $skeleton-opacity;
    width: 100%;
  }

  &__chart-slot {
    margin-block: var(--spacing-40);

    &--hide {
      display: none;
    }

    @include mixins.breakpoint('small') {
      &--hide {
        display: block;
      }
    }
  }

  &__chart-skeleton {
    height: 68px;
    margin-block: var(--spacing-40);
    opacity: $skeleton-opacity;
    width: 100%;
  }

  &__info-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-20);
    justify-content: space-between;

    @include mixins.breakpoint('small') {
      flex-direction: row;
      gap: var(--mdrt-spacing-32);
    }

    @include mixins.breakpoint('medium') {
      gap: 100px;
    }
  }

  &__info-text-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: var(--spacing-4);
  }

  &__info-text {
    color: var(--mdrt-color-text-on-dark-primary);
    font: var(--mdrt-title-3);
  }

  &__info-text-additional {
    color: var(--mdrt-color-text-on-dark-tertiary);
    font: var(--mdrt-title-3);
  }

  &__info-text-skeleton {
    height: 45px;
    opacity: $skeleton-opacity;
    width: 100%;
  }

  &__info-text-additional-skeleton {
    max-width: 610px;
    opacity: $skeleton-opacity;
  }

  &__button-skeleton {
    height: 42px;
    opacity: $skeleton-opacity;
    width: 123px;
  }
}
